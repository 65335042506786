import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Ritixye Autoservice und Reifenmontage
			</title>
			<meta name={"description"} content={"Unsere Autowerkstatt ist Ihr zuverlässiger Partner in der Welt der Autowartung!"} />
			<meta property={"og:title"} content={"FAQ | Ritixye Autoservice und Reifenmontage"} />
			<meta property={"og:description"} content={"Unsere Autowerkstatt ist Ihr zuverlässiger Partner in der Welt der Autowartung!"} />
			<meta property={"og:image"} content={"https://ritixye.com/img/banner.jpeg"} />
			<link rel={"shortcut icon"} href={"https://ritixye.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ritixye.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ritixye.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ritixye.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ritixye.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ritixye.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ritixye.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Antworten auf häufig gestellte Fragen
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Wo kann ich mehr Informationen über Ihre Dienstleistungen erhalten?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Weitere Informationen über unsere Dienstleistungen finden Sie auf unserer Website. Sie können unser Team aber auch jederzeit telefonisch oder per E-Mail kontaktieren, um sich beraten zu lassen.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Welche Arten von Zahlungen akzeptieren Sie?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Wir akzeptieren die folgenden Zahlungsarten: Bargeld, Kredit- und Debitkarten.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Muss ich für den Service einen Termin vereinbaren?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Wir empfehlen Ihnen, einen Termin zu vereinbaren, vor allem während der Zeit des Reifenwechsels, um Ihnen den besten Service zu bieten und Zeit zu sparen. Aber wir akzeptieren auch Kunden, die ohne Termin zu uns kommen.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Bieten Sie einen Einlagerungsservice für einen zweiten Reifensatz oder einen Satz Räder an?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ja, wir bieten einen zweiten Satz Reifen oder einen Satz Felgen mit Reifen in unserem modernen Lager an. So bleiben Ihre Reifen in einem Top-Zustand und sind beim Wechsel der Jahreszeiten praktisch.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Wie oft wird ein Reifenservice empfohlen?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Im Allgemeinen wird empfohlen, Ihre Reifen zweimal im Jahr warten zu lassen - im Frühjahr und im Herbst, wenn sich die Wetterbedingungen ändern. Dies kann jedoch je nach Region und Ihrem Fahrstil variieren. Unsere Experten raten Ihnen, uns für eine Beratung zu kontaktieren.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Welche Garantien bieten Sie für Ihre Reparaturarbeiten?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Wir bieten eine Garantie für alle unsere Reparaturarbeiten. Die Dauer der Garantie kann je nach Art der Arbeit und der verwendeten Ersatzteile variieren. Für weitere Informationen wenden Sie sich bitte an unsere Manager.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});